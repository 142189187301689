import React from "react"
import { Link } from "gatsby"
import navStyles from "./nav.module.scss"
import rfLogo from "../static/logo.png"

export default class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuState : ``,
      menu: props.navType || `main`,
      width: 0,
      height: 0,
      mainMenu: [
        {
          name: `Privates`,
          url: `/privates`
        },
        {
          name: `Events`,
          url: `/events`,
          submenu: [
            {
              name: `Bachata Mondays`,
              url: `/bachata-mondays`
            },
            {
              name: `Bachata Tuesdays`,
              url: `/bachata-mondays`
            },
            {
              name: `Salsa Wednesdays`,
              url: `/salsa-wednesdays`
            },
            {
              name: `Online classes`,
              url: `/online`
            },
          ]
        },
        {
          name: `Teams`,
          url: `/teams`,
          submenu: [
            {
              name: `Bachata`,
              url: `/teams/bachata`
            },
            {
              name: `Salsa`,
              url: `/teams/salsa`
            },
            {
              name: `RF Men`,
              url: `/teams/men`
            },
            {
              name: `RF Ladies`,
              url: `/teams/ladies`
            },
            {
              name: `RF Kids`,
              url: `/teams/kids`
            },
            // {
            //   name: `Training Team`,
            //   url: `/teams/training-team`
            // },
          ]
        },
        {
          name: `Workshops`,
          url: `/workshops`
        },
        {
          name: `Classes`,
          url: `/classes`
        },
        {
          name: `My Account`,
          url: `/account`
        },
        // {
        //   name: `Team Access`,
        //   url: `/app/?no-cache=1`
        //   // url: (window.location.hostname === 'localhost' ? `/team-access` : `/app/?no-cache=1`)
        // },
      ],
      teamMenu: [
        {
          name: `Dashboard`,
          url: `/team-access`
        },
        {
          name: `Classes`,
          url: `/team-access/classes`
        },
        {
          name: `Documents`,
          url: `/team-access/documents`
        },
        {
          name: `Membership`,
          url: `/team-access/membership`
        },
        {
          name: `Main Site`,
          url: `/`
        },
      ]
    }


    this.handleResize = this.handleResize.bind(this)
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleNavClick() {
    this.closeNav()

    const modalClose = (typeof document !== `undefined`  ? document.querySelector('.modal-close') : undefined)

    if (modalClose) {
      modalClose.click()
    }
  }

  handleBurgerClick() {
    if (this.state.width < 1088 ) {
      const newMenuState = this.state.menuState==="" ? "is-active" : "";
      this.setState({ menuState : newMenuState });
    }
  }

  handleResize() {
    this.closeNav()
    this.updateWindowDimensions()
  }

  closeNav() {
    this.setState({ menuState: "" })
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    const menu = (this.state.menu === `main` ? this.state.mainMenu : this.state.teamMenu)

    const getNav = menu.map((item, i) => {
      if (item.submenu) {
        return (
          <div key={i} className="navbar-item has-dropdown is-hoverable">
            <a onClick={() => {this.handleNavClick()}} href={item.url} className={`${navStyles.navbarLink} navbar-link`}>
              {item.name}
            </a>
            <div className={`${navStyles.navbarDropdown} navbar-dropdown`}>
              {item.submenu.map((subItem, index) => (
                <Link key={index} to={subItem.url} className={`${navStyles.navbarLink} navbar-item`}>
                  {subItem.name}
                </Link>
              ))}
            </div>
          </div>
        )
      } else {
        return (
          <a onClick={() => {this.handleNavClick()}} key={i} href={item.url} className={`${navStyles.navbarItem} navbar-item`}>
            {item.name}
          </a>
        )
      }
    })

    return (
      <header className={`${navStyles.navbar} ${(this.props.noHero || this.state.menu === 'team' ? `no-hero` : ``)} navbar`}>
        <div className={`${navStyles.navContainer} container`}>
          <div className={`${navStyles.navbarBrand} ${this.state.menuState} navbar-brand`}>
            <Link onClick={() => {this.handleNavClick()}} className={navStyles.navbarItem} to="/"><img src={rfLogo} alt="RF Dance" /> RF Dance</Link>
            <span onClick={() => {this.handleBurgerClick()}} className={`${navStyles.navbarBurger} ${this.state.menuState} navbar-burger burger`} >
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div className={`overlay ${this.state.menuState}`} onClick={() => {this.handleNavClick()}} />
          <div className={`${this.state.menuState || 'is-inactive'} ${navStyles.navbarMenu} navbar-menu`}>
            <div className="navbar-end">
              {getNav}
            </div>
          </div>
        </div>
      </header>
    )
  }
}