import React from "react"
import Helmet from "react-helmet"
import Logo from "../images/logo.jpg"
import Favicon from "../static/favicon.ico"

export default props => (
  <Helmet>
    <title>{props.title}</title>
    <link rel="canonical" href={`https://rfdance.com/${props.pathname}`} />
    <meta
      name="viewport"
      content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
    />
    <meta name="description" content={props.description} />
    <meta property="og:url" content={`https://rfdance.com/${props.pathname}`} />
    <meta property="og:type" content="website" />
    <meta property="og:locale" content="en" />
    <meta property="og:site_name" content="RF Dance" />
    <meta property="og:image" content={(props.img ? props.img : Logo)} />
    <meta property="og:image:width" content="445" />
    <meta property="og:image:height" content="372" />
    <meta property="fb:app_id" content="296988051001046" />
    <meta name="robots" content={(props.robots ? props.robots : 'index')} />

    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content="https://twitter.com/RhythmicFusion" />
    <link rel="manifest" href="/manifest.webmanifest" />
    <link rel="icon" sizes="144x144" href={Favicon} />
    />
  </Helmet>
)